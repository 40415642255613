import { render, staticRenderFns } from "./MaterialStock.vue?vue&type=template&id=0542ca80&scoped=true"
import script from "./MaterialStock.vue?vue&type=script&lang=js"
export * from "./MaterialStock.vue?vue&type=script&lang=js"
import style0 from "./MaterialStock.vue?vue&type=style&index=0&id=0542ca80&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0542ca80",
  null
  
)

component.options.__file = "MaterialStock.vue"
export default component.exports